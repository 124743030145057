import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <p className="header">THANK YOU FOR VISITING MY WEBSITE!</p>
      <br />
      <br />
      <br />
      <br />
      <p className="description">
        If you encounter an error of any kind, or something doesn't look right
        please reach out to me!!
      </p>
    </div>
  );
}

export default Footer;
