import React from "react";
import "../../App.css";
import "./Pages.css";

export default function Projects() {
  return (
    <div className="page-container">
      <h1>PROJECTS</h1>
      <div className="column-layout">
        <div className="left-align">
          <h1>This Website</h1>
          <div className="dates">
            <h1>July 2024</h1>
          </div>
          <ul>
            <li>
              <p>Created this website using React.</p>
            </li>
            <li>
              <p>
                I can tell you how interactive, adaptable, and user-friendly it
                is, but I'll let you be the judge of that. Go easy on me, this
                is my first experience with web development
              </p>
            </li>
          </ul>
          <br />
          <h1>Battleship</h1>
          <div className="dates">
            <h1>December 2023</h1>
          </div>
          <ul>
            <li>
              <p>Created a battleship game using Java Swing</p>
            </li>
            <li>
              <p>
                Implemented a user-friendly and intuitive GUI with sound effects
              </p>
            </li>
            <li>
              <p>
                Demonstrated proficiency in code organization and documentation
                for ease of collaboration
              </p>
            </li>
            <li>
              <p>
                Implemented dynamic graphics, animations, and game modes for an
                engaging user experience
              </p>
            </li>
          </ul>
          <br />
          <br />
          <h1>Loan Report Automation - Wintrust Financial Internship</h1>
          <div className="dates">
            <h1>July 2024</h1>
          </div>
          <ul>
            <li>
              <p>
                Developed a software bot that automated a daily process for the
                Mortgage department that used to require an employee to get up
                every morning at 5am and spend nearly 1 hour generating and
                submitting a loan report for internal audit
              </p>
            </li>
            <li>
              <p>Automated using UiPath</p>
            </li>
            <li>
              <p>Saved the company over 250 man-hours a year</p>
            </li>
          </ul>
          <br />
          <br />
          <h1>Hole-finding Algorithm - Research Assistant</h1>
          <div className="dates">
            <h1>October 2023</h1>
          </div>
          <ul>
            <li>
              <p>
                Utilized Python and OpenCV to develop software that would more
                accurately and efficiently identify the "holes" in samples for
                cryo electron microscopy
              </p>
            </li>
          </ul>
          <br />
          <br />
          <h1>XML Adapter Code - Research Assistant</h1>
          <div className="dates">
            <h1>February 2024</h1>
          </div>
          <ul>
            <li>
              <p>
                Used Python to parse XML metadata from other platforms and
                translated to be used on our platform.
              </p>
            </li>
          </ul>
          <br />
          <br />
          <h1>MRC File Viewer - Research Assistant</h1>
          <div className="dates">
            <h1>October 2024</h1>
          </div>
          <ul>
            <li>
              <p>
                Developed a standalone MRC file viewer using the MRCFile module
                and Tkinter in Python
              </p>
            </li>
            <li>
              <p>
                MRC files, the standard for cryo electron microscopy, are large
                3-dimensional files split up into "slices" and can be difficult
                to view and navigate
              </p>
            </li>
            <li>
              <p>Implemented proper memory management to handle large files</p>
            </li>
          </ul>
          <br />
          <br />
          <h1>Brand Classification Code - Access2Insight Internship</h1>
          <div className="dates">
            <h1>July 2023</h1>
          </div>
          <ul>
            <li>
              <p>
                Updated the parameters by which different brands are identified
                within our relational database based off of receipt data
              </p>
            </li>
            <li>
              <p>
                New code increased efficiency and accuracy when pulling data for
                our brands
              </p>
            </li>
          </ul>
          <br />
          <br />
          <h1>Sales Lift Study - Access2Insight Internship</h1>
          <div className="dates">
            <h1>August 2023</h1>
          </div>
          <ul>
            <li>
              <p>
                Utilized SQL to identify different users' spending habits before
                and after being exposed to our marketing
              </p>
            </li>
            <li>
              <p>Calculated the value of our marketing and expected ROI</p>
            </li>
            <li>
              <p>Visualized the results using excel</p>
            </li>
            <li>
              <p>Results would later be presented to potential clients</p>
            </li>
          </ul>
          <br />
        </div>
        <div className="right-align"></div>
      </div>
    </div>
  );
}
