import React from "react";
import "../../App.css";
import "./Pages.css";

export default function RelevantExperience() {
  return (
    <div className="page-container">
      <h1>RELEVANT EXPERIENCE</h1>
      <div className="column-layout">
        <div className="left-align">
          <div className="title">
            <h1>Technical Experience</h1>
          </div>
          <h1>Incoming Software Development Intern - Raymond James</h1>
          <div className="dates">
            <h1>Summer 2025</h1>
          </div>
          <ul>
            <li>
              <p>Work on the cloud services team</p>
            </li>
            <li>
              <p>More to come soon!</p>
            </li>
          </ul>
          <br />

          <h1>Research Assistant - Stagg Lab FSU</h1>
          <div className="dates">
            <h1>September 2023 - Present</h1>
          </div>
          <ul>
            <li>
              <p>
                Leverage Python and real-time image processing to advance
                Magellon, the next generation of software for automated data
                collection and processing in cryo-electron microscopy
              </p>
            </li>
            <li>
              <p>
                Enhanced hole-finding algorithms using OpenCV and developed XML
                adapter code to improve data integration and processing from
                other platforms
              </p>
            </li>
            <li>
              <p>
                Developed an interactive MRC file viewer with proper memory
                management for large files
              </p>
            </li>
            <li>
              <p>
                Read more{" "}
                <a
                  href="https://docs.magellon.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </p>
            </li>
          </ul>
          <br />

          <h1>Software Development Intern - Wintrust Financial</h1>
          <div className="dates">
            <h1>May 2024 - August 2024</h1>
          </div>
          <ul>
            <li>
              <p>
                Developed software bots using UiPath for one of Chicagoland’s
                largest banks($60bn+ in assets)
              </p>
            </li>
            <li>
              <p>
                Automated a previously manual daily loan report submission,
                resulting in an increase in accuracy, and savings of
                approximately 250 hours and $20,000 in operational costs
                annually
              </p>
            </li>
          </ul>
          <br />

          <h1>Data Analyst Intern - Access2Insight</h1>
          <div className="dates">
            <h1>May 2023 - August 2023</h1>
          </div>
          <ul>
            <li>
              <p>
                Utilized SQL and Excel to import, clean, and transfer data from
                extensive relational databases with nearly 100,000,000
                registered users and billions of records
              </p>
            </li>
            <li>
              <p>
                Analyzed data to calculate sales lift that demonstrated
                marketing effectiveness which would be presented to potential
                clients
              </p>
            </li>
            <li>
              <p>
                Updated brand classification code to increase accuracy and
                efficiency when identifying brands’ transactions in our database
              </p>
            </li>
          </ul>
          <br />

          <div className="title">
            <h1>Leadership</h1>
          </div>

          <h1>Lead Teaching Assistant - FSU Department of Computer Science</h1>
          <div className="dates">
            <h1>June 2024 - Present</h1>
          </div>
          <ul>
            <li>
              <p>
                Serve as a support resource for over 50 students in an NSF
                funded computer science scholarship program
              </p>
            </li>
            <li>
              <p>
                Conduct weekly office hours, provide individual support, host
                workshops, and assist with college life challenges
              </p>
            </li>
            <li>
              <p>
                Coordinate and lead the efforts of other teaching assistants to
                ensure a cohesive and effective support system
              </p>
            </li>
          </ul>
          <br />

          <h1>Director of Outreach - 'Noles Everywhere</h1>
          <div className="dates">
            <h1>April 2023 - Present</h1>
          </div>
          <ul>
            <li>
              <p>
                Lead outreach efforts to create a welcoming environment for
                out-of-state students by organizing events and building
                partnerships.
              </p>
            </li>
            <li>
              <p>
                Manage the Outreach Committee, expanding the program's network
                through collaboration and direct student engagement.
              </p>
            </li>
          </ul>
          <br />

          <h1>President - Association for Computing Machinery @ FSU</h1>
          <div className="dates">
            <h1>April 2023 - April 2024</h1>
          </div>
          <ul>
            <li>
              <p>
                Led the largest computer science organization at Florida State
                University, with over 420 members, and received the
                Undergraduate Leadership Award for exceptional contributions.
              </p>
            </li>
            <li>
              <p>
                Achieved a 50% increase in membership (140+ new members) and
                more than a 225% rise in average meeting attendance
              </p>
            </li>
          </ul>
          <br />

          <div className="title">
            <h1>On-campus Work</h1>
          </div>

          <h1>
            Committee Member - Student Council for Undergraduate Research and
            Creativity
          </h1>
          <div className="dates">
            <h1>August 2024 - Present</h1>
          </div>
          <ul>
            <li>
              <p>
                Advocate for increased accessibility and approachability of
                research opportunities for undergraduate students at FSU
              </p>
            </li>
            <li>
              <p>
                Serve on the programming committee to design, plan, and staff
                events that promote undergraduate research involvement
              </p>
            </li>
          </ul>
          <br />

          <h1>Tour Guide - The Torch Society</h1>
          <div className="dates">
            <h1>October 2024 - Present</h1>
          </div>
          <ul>
            <li>
              <p>
                Provide personalized 1 on 1 campus tours to outstanding students
                accepted to FSU
              </p>
            </li>
          </ul>
          <br />

          <div className="title">
            <h1>Other</h1>
          </div>

          <h1>Department Editor - Association for Computing Machinery</h1>
          <div className="dates">
            <h1>July 2024 - Present</h1>
          </div>
          <ul>
            <li>
              <p>
                Editor for XRDS, ACM’s premier magazine for computer science
                students with over 1,000,000 total readers
              </p>
            </li>
            <li>
              <p>
                Conduct in-depth research on each issue’s theme and produce a
                comprehensive, original article
              </p>
            </li>
            <li>
              <p>Currently leading the efforts of our Winter 2025 issue</p>
            </li>
          </ul>
          <br />
          <h1>Languages</h1>
          <ul>
            <li>
              <p>C#</p>
            </li>
            <li>
              <p>C++</p>
            </li>
            <li>
              <p>Python</p>
            </li>
            <li>
              <p>SQL</p>
            </li>
            <li>
              <p>Java</p>
            </li>
            <li>
              <p>JSX</p>
            </li>
            <li>
              <p>CSS</p>
            </li>
          </ul>
          <br />
          <h1>Skills</h1>
          <ul>
            <li>
              <p>Data structures and algorithms</p>
            </li>
            <li>
              <p>UiPath</p>
            </li>
            <li>
              <p>Unix</p>
            </li>
            <li>
              <p>Git</p>
            </li>
            <li>
              <p>React</p>
            </li>
            <li>
              <p>Microsoft Office</p>
            </li>
          </ul>
        </div>
        <div className="right-align">
          <img src="../../images/img-10.jpg" alt="Me" />
          <p className="caption">Feautured on Wintrust Social Media</p>
          <img src="../../images/img-8.jpg" alt="Me" />
          <p className="caption">Access2Insight Internship</p>
          <img src="../../images/img-7737.jpg" alt="Me" />
          <p className="caption">
            Me presenting my research at the 2024 Undergraduate Research
            Symposium
          </p>
          <img src="../../images/img-2.jpg" alt="Me" />
          <p className="caption">'Noles Everywhere Headshot</p>
        </div>
      </div>
    </div>
  );
}
