import React from "react";
import "../../App.css";
import "./Pages.css";

export default function AboutMe() {
  return (
    <div className="page-container">
      <h1>ABOUT ME</h1>
      <div className="page-body">
        <p>
          I am from Chicago, and I love my city to death. I spend a lot of my
          free time there going for runs, walks, or bike rides along the lake
          and river. I also really enjoy long walks downtown and admiring the
          world-class architecture. Here are a few of my favorite pictures of
          Chicago, including my favorite building, the St. Regis Chicago(left).
          Designed by Jeanne Gang, it is the tallest building in the world
          designed by a woman.
        </p>
        <div className="image-container">
          <img src="../../images/img-0998.jpg" alt="Me" />
          <div className="mobile-image">
            <img src="../../images/img-1407.jpg" alt="Me" />
          </div>
          <img src="../../images/img-0243.jpg" alt="Me" />
        </div>
        <p>
          My passion for programming started in high school when I took an intro
          to computer science class. I was immediately hooked. I found myself
          diving into homework assignments long after they were due, simply
          because I enjoyed them so much. Discovering that I could have this
          much fun in school made me realize this was what I wanted to pursue in
          college and beyond.{" "}
        </p>
        <p>
          I'm particularly interested in software engineering, bioinformatics,
          financial technology, and artificial intelligence. Long term, all I
          can ask for is to create a career where I can leverage technology and
          computational methods to solve complex problems that make people's
          lives easier.
        </p>
        <div className="image-container">
          <img src="../../images/img-4.jpg" alt="Me" />
          <div className="mobile-image">
            <img src="../../images/img-5.jpg" alt="Me" />
          </div>
        </div>
        <p>
          Outside of school and work, I love working out, reading, spending time
          outside, and listening to music. Some of my favorite genres of music
          are classical piano, Japanese jazz piano, and Chicago rap. My favorite
          artists respectively are Franz Liszt, Ryo Fukui, and Polo G. Some of
          my favorite books I've read are 'The Wasp Factory' by Iain Banks, 'The
          Untethered Soul' by Michael Singer, and 'Project Hail Mary' by Andy
          Weir. I can also cook, but only if you're talking about steak, eggs,
          or mac and cheese.
        </p>
        <div className="image-container">
          <img src="../../images/img-6.jpg" alt="Me" />
          <img src="../../images/img-0719.jpg" alt="Me" />
        </div>
        <p>
          Finally, I don't think you will ever meet someone who enjoys sunrises
          and sunsets as much as I do. I make an effort to see both every single
          day. When in Tallahassee, I am fortunate enough to have a balcony with
          perfect views of both. Enjoying breakfast and coffee while I watch the
          sunrise, and then listening to music and reading a book while I watch
          the sunset is easily my favorite part of every day. Here are a few of
          my favorite photos I captured on my balcony.
        </p>
        <div className="image-container">
          <img src="../../images/img-6571.jpg" alt="Me" />
          <img src="../../images/img-6867.jpg" alt="Me" />
        </div>
        <div className="image-container">
          <img src="../../images/img-6961.jpg" alt="Me" />
          <img src="../../images/img-7097.jpg" alt="Me" />
        </div>
      </div>
    </div>
  );
}
